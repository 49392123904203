<template>
  <div class="box has-background">
    <h1 class="title is-4">
      <b-icon icon="bank"></b-icon>
      Dane konta bankowego
    </h1>
    <hr />
    <b-field label="Nazwa banku">
      <b-input
        v-model="bankName"
        type="text"
        size="is-small"
      ></b-input>
    </b-field>
    <b-field label="Nr konta">
      <b-input
        v-model="bankNumber"
        type="text"
        size="is-small"
      ></b-input>
    </b-field>
    
    <b-button
      @click="save"
      type="is-primary"
      icon-left="check"
      size="is-small"
      :disabled="validate"
      outlined
      >Aktualizuj</b-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      bankName: '',
      bankNumber: ''
    };
  },
    methods: {
    save() {
      this.$store.dispatch("companyProfile/updateBankData", {
        id: this.id,
        bank: {
          name: this.bankName,
          number: this.bankNumber
        }, 
        token: this.token
      })
    },
  },
  computed: {
    validate(){try{

      const validate = !!this.bankName  && !!this.bankNumber  && this.bankName.length > 0 && this.bankAccount.length < 255 &&
      this.bankNumber.length == 26
      return validate
    }catch(err){
      return false
    }
    },
    account: {
      get() {
        try{
          return this.$store.state.auth.user.details?.bank_name
        }catch(err){
          return ''
        }
      },
    },
    number: {
      get() {
        try{
          return this.$store.state.auth.user.details?.bank_number
        }catch(err){
          return ''
        }
      },
    },
        id(){
      return this.$store.state.auth.id
    },
    token(){
      return this.$store.state.auth.token
    }
  },
mounted(){
    this.bankName = this.account 
    this.bankNumber = this.number
  },

};
</script>