<template>
  <div class="box has-background">
    <h1 class="title is-4">
      <b-icon icon="key"></b-icon>
      Hasło
    </h1>
    <hr />
    <b-field label="Stare hasło">
      <b-input
        v-model="form.oldPassword"
        type="password"
        size="is-small"
      ></b-input>
    </b-field>
    <b-field label="Nowe hasło">
      <b-input
        v-model="form.newPassword"
        type="password"
        size="is-small"
      ></b-input>
    </b-field>
    <b-field label="Powtórz nowe hasło">
      <b-input
        v-model="form.newPasswordRepeat"
        type="password"
        size="is-small"
      ></b-input>
    </b-field>
    <b-button
      @click="save"
      type="is-primary"
      icon-left="check"
      size="is-small"
      outlined
      >Zmień</b-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: "",
      },
    };
  },
  computed: {
    type: {
      get() {
        return this.$store.state.auth.type;
      },
    },
    id: {
      get() {
        return this.$store.state.auth.id;
      },
    },
  },

  methods: {
    save() {
      if (this.form.newPassword != this.form.newPasswordRepeat) {
        this.$buefy.toast.open({
          message: "Nie udało się potwierdzić nowego hasła!",
          type: "is-danger",
        });
        return;
      }

      const payload = {
        userId: this.id,
        old: this.form.oldPassword,
        new: this.form.newPassword,
      };

      if (this.type == "user") {
        this.$store
          .dispatch("clientProfile/changePassword", payload)
          .then((response) => {
            //console.log(response);
            if (response.status == 201) {
              this.form = {
                oldPassword: "",
                newPassword: "",
                newPasswordRepeat: "",
              };

              this.$buefy.toast.open({
                message: "Hasło zostało zmienione!",
                type: "is-primary",
              });
            } else
              this.$buefy.toast.open({
                message: "Nie udało się zmienić hasła! ",
                type: "is-danger",
              });
          })
          .catch((error) => {
            //console.log(error);
            this.$buefy.toast.open({
              message: "Nie udało się zmienić hasła! " + error,
              type: "is-danger",
            });
          });
      } else if (this.type == "company") {
        payload.companyId = this.id;
        this.$store
          .dispatch("companyProfile/changePassword", payload)
          .then((response) => {
            //console.log(response);
            if (response.status == 201) {
              this.form = {
                oldPassword: "",
                newPassword: "",
                newPasswordRepeat: "",
              };
              this.$buefy.toast.open({
                message: "Hasło zostało zmienione!",
                type: "is-primary",
              });
            } else
              this.$buefy.toast.open({
                message: "Nie udało się zmienić hasła! ",
                type: "is-danger",
              });
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: "Nie udało się zmienić hasła! " + error,
              type: "is-danger",
            });
          });
      }
      /* this.$store
        .dispatch("clientProfile/changePassword", this.form)
        .then(() => {
          for (let field in this.form) {
            this.form[field] = "";
          }
        }); */
    },
  },
};
</script>