<template>
  <div>
    <div class="box" v-if="logo">
      <img width="128"  :src="logo" alt="">
    </div>
    <div class="box block">
      <div v-if="isCompanyDataEditing">
        <company-data-editor
          @closeCompanyDataEditor="closeAllEditors"
        ></company-data-editor>
      </div>
      <div v-else>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4">Informacje</h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                @click="isCompanyDataEditing = true"
                size="is-small"
                type="is-primary"
                icon-left="pencil"
                >Edytuj</b-button
              >
            </div>
          </div>
        </div>
        <div>Nazwa: {{ profile.name }}</div>
        <div>NIP: {{ profile.details.NIP }}</div>
        <div>REGON: {{ profile.details.REGON }}</div>
        <div>Opis: {{ profile.details.description }}</div>
      </div>
    </div>
    <div class="box block">
      <div v-if="isAddressEditing">
        <address-editor @closeAddressEditor="closeAllEditors"></address-editor>
      </div>
      <div v-else>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4">Adres</h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                @click="isAddressEditing = true"
                size="is-small"
                type="is-primary"
                icon-left="pencil"
                >Edytuj</b-button
              >
            </div>
          </div>
        </div>
        <div>
          {{ profile.address[0].postcode }}
          {{ profile.address[0].city }},
          {{ profile.address[0].street }}
          {{ profile.address[0].local_nb }}
        </div>
      </div>
    </div>
    <div class="box block">
      <div v-if="isContactEditing">
        <contact-editor @closeContactEditor="closeAllEditors"></contact-editor>
      </div>
      <div v-else>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title is-4">Dane kontaktowe</h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button
                @click="isContactEditing = true"
                size="is-small"
                type="is-primary"
                icon-left="pencil"
                >Edytuj</b-button
              >
            </div>
          </div>
        </div>
        <div>Email: {{ profile.email }}</div>
        <div>Telefon: {{ profile.details.phone }}</div>
      </div>
    </div>
    <!-- Zmiana hasła box -->
    <div class="column">
      <password-editor></password-editor>
    </div>
    <!-- Konto bankowe -->
    <div class="column">
      <account-bank-editor></account-bank-editor>
    </div>
        <!-- Logo firmy -->
    <div class="column">
      <logo-editor :logoUrl="logo"></logo-editor>
    </div>
  </div>
</template>

<script>
import CompanyDataEditor from "@/components/company/editors/CompanyDataEditor";
import AddressEditor from "@/components/company/editors/AddressEditor";
import ContactEditor from "@/components/company/editors/ContactEditor";
import PasswordEditor from "@/components/editors/PasswordEditor";
import AccountBankEditor from "@/components/editors/AccountBankEditor"
import LogoEditorVue from '../../components/editors/LogoEditor.vue';
export default {
  components: {
    "company-data-editor": CompanyDataEditor,
    "address-editor": AddressEditor,
    "contact-editor": ContactEditor,
    "password-editor": PasswordEditor,
    "account-bank-editor": AccountBankEditor,
    "logo-editor": LogoEditorVue,
  },

  data() {
    return {
      isCompanyDataEditing: false,
      isAddressEditing: false,
      isContactEditing: false,
    };
  },

  methods: {
    closeAllEditors() {
      this.isCompanyDataEditing = false;
      this.isAddressEditing = false;
      this.isContactEditing = false;
    },
  },

  computed: {
    profile() {
      return this.$store.getters["companyProfile/profile"];
    },
    logo(){
      return this.profile.details.logo_url || ''
    }
  },
};
</script>