<template>
  <div>
    <h1 class="title is-4">Adres - edycja</h1>
    <div class="columns is-multiline is-vcentered">
      <div class="column is-three-quarters">
        <b-field label="Miasto">
          <b-input v-model="city" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Kod pocztowy">
          <b-input v-model="postcode" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-three-quarters">
        <b-field label="Ulica">
          <b-input v-model="street" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Nr domu/lokalu">
          <b-input v-model="number" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-full">
        <b-button
          type="is-success"
          outlined
          icon-left="check"
          class="mr-3"
          size="is-small"
          @click="saveAndClose"
          >Zapisz</b-button
        >
        <b-button
          type="is-danger"
          outlined
          icon-left="close"
          size="is-small"
          @click="closeEditor"
          >Anuluj</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.form.city = this.city;
    this.form.postcode = this.postcode;
    this.form.street = this.street;
    this.form.number = this.number;
  },

  data() {
    return {
      form: {
        city: "",
        postcode: "",
        street: "",
        number: "",
      },
    };
  },

  methods: {
    saveAndClose() {
      const payload = {
        company: {},

        details: {},

        address: {
          city: this.form.city,
          postcode: this.form.postcode,
          street: this.form.street,
          local_nb: this.form.number,
        },
      };

      this.$store
        .dispatch("companyProfile/updateAddress", payload)
        .then((response) => {
          if (response.status !== 201) {
            this.editFailedNotification();
          } else {
            this.editSuccessNotification();
          }
        })
        .catch((err) => {
          this.editFailedNotification(err.message);
        })
        .finally(() => {
          this.closeEditor();
        });
    },

    closeEditor() {
      this.$emit("closeAddressEditor");
    },

    editSuccessNotification() {
      this.$buefy.notification.open({
        message: "Pomyślnie zmieniono adres",
        type: "is-primary",
        position: "is-bottom",
        duration: 10000,
      });
    },

    editFailedNotification(msg = "Nie udało się zmienić danych") {
      this.$buefy.notification.open({
        message: msg,
        type: "is-danger",
        position: "is-bottom",
        duration: 10000,
      });
    },
  },

  computed: {
    city: {
      get() {
        return this.$store.getters["companyProfile/address"].city;
      },

      set(value) {
        this.form.city = value;
      },
    },

    postcode: {
      get() {
        return this.$store.getters["companyProfile/address"].postcode;
      },

      set(value) {
        this.form.postcode = value;
      },
    },

    street: {
      get() {
        return this.$store.getters["companyProfile/address"].street;
      },

      set(value) {
        this.form.street = value;
      },
    },

    number: {
      get() {
        return this.$store.getters["companyProfile/address"].local_nb;
      },

      set(value) {
        this.form.number = value;
      },
    },
  },
};
</script>