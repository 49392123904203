<template>
  <div>
    <h1 class="title is-4">Informacje - edycja</h1>
    <div class="columns is-multiline">
      <div class="column is-full">
        <b-field label="Nazwa">
          <b-input v-model="companyName" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-full">
        <b-field label="NIP">
          <b-input v-model="nip" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-full">
        <b-field label="REGON">
          <b-input v-model="regon" size="is-small"></b-input>
        </b-field>
      </div>
      <div class="column is-full">
        <b-field label="Opis">
          <b-input
            v-model="description"
            size="is-small"
            type="textarea"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-full">
        <b-button
          type="is-success"
          outlined
          icon-left="check"
          class="mr-3"
          size="is-small"
          @click="saveAndClose"
          >Zapisz</b-button
        >
        <b-button
          type="is-danger"
          outlined
          icon-left="close"
          size="is-small"
          @click="closeEditor"
          >Anuluj</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.form.nip = this.nip;
    this.form.regon = this.regon;
    this.form.description = this.description;
  },

  data() {
    return {
      form: {
        companyName: "",
        nip: "",
        regon: "",
        description: "",
      },
    };
  },

  methods: {
    saveAndClose() {
      const payload = {
        company: {},

        details: {
          NIP: this.form.nip,
          REGON: this.form.regon,
          description: this.form.description,
        },

        address: {},
      };

      /* 
            Parametr name powinien być dołączony tylko wtedy, gdy zostanie zmieniony.
            W przeciwnym razie, wyświetli się błąd edycji danych z powodu "już istnieje taka nazwa firmy" 
            */
      if (this.form.companyName !== "") {
        payload.company.name = this.form.companyName;
      }

      this.$store
        .dispatch("companyProfile/updateCompanyData", payload)
        .then((response) => {
          if (response.status !== 201) {
            this.editFailedNotification();
          } else {
            this.editSuccessNotification();
          }
        })
        .catch((err) => {
          this.editFailedNotification(err.message);
        })
        .finally(() => {
          this.closeEditor();
        });
    },

    closeEditor() {
      this.$emit("closeCompanyDataEditor");
    },

    editSuccessNotification() {
      this.$buefy.notification.open({
        message: "Pomyślnie zmieniono dane",
        type: "is-primary",
        position: "is-bottom",
        duration: 10000,
      });
    },

    editFailedNotification(msg = "Nie udało się zmienić danych") {
      this.$buefy.notification.open({
        message: msg,
        type: "is-danger",
        position: "is-bottom",
        duration: 10000,
      });
    },
  },

  computed: {
    companyName: {
      get() {
        return this.$store.getters["companyProfile/profile"].name;
      },

      set(value) {
        this.form.companyName = value;
      },
    },

    nip: {
      get() {
        return this.$store.getters["companyProfile/profile"].details.NIP;
      },

      set(value) {
        this.form.nip = value;
      },
    },

    regon: {
      get() {
        return this.$store.getters["companyProfile/profile"].details.REGON;
      },

      set(value) {
        this.form.regon = value;
      },
    },

    description: {
      get() {
        return this.$store.getters["companyProfile/profile"].details
          .description;
      },

      set(value) {
        this.form.description = value;
      },
    },
  },
};
</script>