<template>
  <div class="box has-background">
    <h1 class="title is-4">
      <b-icon icon="image"></b-icon>
      Logo firmy
    </h1>
    <hr />
    <b-field>
      <img v-if="logo" :src="logo" alt="">
      <span v-else>Brak logo firmy</span>
    </b-field>
    <b-field class="file is-primary" :class="{'has-name': !!file}">
        <b-upload v-model="file" class="file-label" accept="image/png,image/jpeg,image/jpg">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Dodaj nowe logo</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
        </b-upload>
    </b-field>
    
    <b-button
      @click="updateLogo"
      type="is-primary"
      icon-left="check"
      size="is-small"
      :disabled="!file"
      outlined
      >Aktualizuj</b-button
    >
  </div>
</template>
<script>
export default {
  name: 'LogoEditor',
  data() {
    return {
      file: null,
      logo: this.logoUrl
    };
  },
  props: {
    logoUrl: String
  },

    methods: {
    async updateLogo() {
      const res = await this.$store.dispatch("companyProfile/updateLogo", {
        id: this.id,
        file: this.file,
        token: this.token,
      })
      if(res){
        this.logo = res
        this.$store.commit("companyProfile/setLogo", this.logo)
        window.scrollTo(0,0)
      }
    },
  },
  computed: {
    id(){
      return this.$store.state.auth.id
    },
    token(){
      return this.$store.state.auth.token
    }
  },
mounted(){

  },

};
</script>
<style lang="scss" scoped>
img{
  max-width: 128px;
}
</style>